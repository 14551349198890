import { render, staticRenderFns } from "./yjm-empty.vue?vue&type=template&id=b1677476"
import script from "./yjm-empty.vue?vue&type=script&lang=js"
export * from "./yjm-empty.vue?vue&type=script&lang=js"
import style0 from "./yjm-empty.vue?vue&type=style&index=0&id=b1677476&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_css-loader@6.8.1_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports