import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import search from './search'
import evtActivit from './evtActivit'
import organization from './organization'
import myOrder from './myOrder'
import activitDate from './activitDate'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/login'),
    },
    {
        path: '/studyLogin',
        name: 'studyLogin',
        component: () => import('@/views/studyLogin'),
    },
    {
        path: '/userLogin',
        name: 'userLogin',
        component: () => import('@/views/userLogin'),
        meta: {
            isMask: false
        }
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index'),
        meta: {
            isMask: true
        }
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('@/views/error')
    },
    {
        path: '/other',
        name: 'other',
        component: () => import('@/views/other'),
    },
    ...search,
    ...evtActivit,
    ...organization,
    ...myOrder,
    ...activitDate,

];
const router = new VueRouter({
    routes,
});
// 路由监听
router.beforeEach((to, from, next) => {
    //增加了全局loading遮罩，当axios返回的时候，会关闭遮罩，当前关闭方法在ajaxMethods.js这个文件中
    store.commit('setLoading', true);
    $("html").scrollTop(0);
    $("body").scrollTop(0);
    next();
});
// 路由监听
router.afterEach((to, from) => {
    if (store.state.loading) {
        setTimeout(() => {
            store.commit('setLoading', false);
        }, 3500);
    }

    if (!to.meta.isMask) {
        store.commit('setLoading', false) //关闭遮罩
    }
});
export default router

export const useRoute = () => {
    return router.currentRoute
}

export const useRouter = () => {
    return router
}
