import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  strict:true,
  state: {
      isUpdate:{},
      loading:false,
      optloading:false
  },
  mutations: {
      changeIsUpdate(state,val){
          state.isUpdate=val
      },
      setLoading(state,val){state.loading=val},
      setOptloading(state,val){state.optloading=val},
  },
  actions: {
  },
  modules: {
  }
})
