export default[
    {
        path: '/myOrder',
        name: 'myOrder',
        component: ()=> import('@/views/myOrder/'),
        meta:{
            isMask:true
        }
    },
    {
        path: '/myOrder/orderMain',
        name: 'orderMain',
        component: ()=> import('@/views/myOrder/orderMain'),
    },
    {
        path: '/myOrder/refundAdd',
        name: 'refundAdd',
        component: ()=> import('@/views/myOrder/refundAdd'),
    },
    {
        path: '/myOrder/refundSuccess',
        name: 'refundSuccess',
        component: ()=> import('@/views/myOrder/refundSuccess'),
    },
];
