export default[
    {
        path: '/search',
        name: 'search',
        component: ()=> import('@/views/search/index'),
    },
    {
        path: '/search/searchGoods',
        name: 'searchGoods',
        component: ()=> import('@/views/search/searchGoods'),
    },
    {
        path: '/search/searchMsg',
        name: 'searchMsg',
        component: ()=> import('@/views/search/searchMsg'),
    },
];
