export default[
    {
        path: '/evtActivit',
        name: 'evtActivit',
        component: ()=> import('@/views/evtActivit/')
    },
    {
        path: '/evtActivit/evtMain',
        name: 'evtMain',
        component: ()=> import('@/views/evtActivit/evtMain'),
    },
    {
        path: '/evtActivit/signActivit',
        name: 'signActivit',
        component: ()=> import('@/views/evtActivit/signActivit'),
    },
    {
        path: '/evtActivit/payBox',
        name: 'payBox',
        component: ()=> import('@/views/evtActivit/payBox'),
    },
    {
        path: '/evtActivit/signSuccess',
        name: 'signSuccess',
        component: ()=> import('@/views/evtActivit/signSuccess'),
    },
    {
        path: '/evtActivit/selectPay',
        name: 'selectPay',
        component: ()=> import('@/views/evtActivit/selectPay'),
    },
];
