<style>
  .pubLoading{position: fixed;top:0;left:0;width:100%;height:100%;display: flex;align-items: center;justify-content: center;
    background: rgba(255,255,255,1);z-index:200;}
  .pubLoading.opt{background: rgba(255,255,255,0)}
</style>
<template>
  <div id="app" style="position: relative;" :style="{height:optloading?'100vh':'auto',overflow:optloading?'hidden':''}">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <van-col span="24" class="pubLoading" v-show="loading">
      <van-loading color="#1989fa" vertical></van-loading>
    </van-col>
    <van-col span="24" class="pubLoading opt" v-show="optloading">
      <van-loading color="#1989fa" vertical></van-loading>
    </van-col>
  </div>
</template>
<script>
  export default {
      name: "app",
      computed:{
          loading(){
              return this.$store.state.loading
          },
          optloading(){
              return this.$store.state.optloading
          },
      }
  }
</script>
