import Vue from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'

//业务上常用的js和css放入到这里面
import '@/assets/css/index.css';
import '@/assets/js';

import yjmheader from './components/myVant/yjm-header'
import yjmEmpty from './components/myVant/yjm-empty'

Vue.config.productionTip = false;

Vue.use(Vant);
Vue.prototype.$axios = axios;

Vue.prototype.subString = function(val='0000-00-00',begin=0,end=10){
    return val.substring(begin,end)
};
Vue.prototype.showPay = function(val){
    if((val==null||val==''||val==undefined)&&val!=0){
        return ""
    }
    return val==0?'免费':'￥'+val
};
Vue.prototype.showPayLimit = function(val=''){
   let resArray = strToArr(val,'-');
   let resData ;
   if(isEmpty(val)){
       resData='';
   }else{
       if(resArray.length>1){
           resData = `￥${resArray[0]}-￥${resArray[1]}`
       }else{
           if(val==0){
               resData='免费'
           }else{
               resData = '￥'+val;
           }
       }
   }
   return resData
};
Vue.prototype.showPeople = function(val=''){
    let resData =  val==0?'不限报名人数':'限制'+val+'人报名参加';
    return resData
};
//公共头部
Vue.component("yjmHeader",yjmheader);
Vue.component("yjmEmpty",yjmEmpty);

let vm =new Vue({
  data(){
    return{
        //全局的日期组件的可选择区间
        minDate:'2020-01-01',
        maxDate:getNowTime(),
        defaultImage:"https://dummyimage.com/214x138",
    }
  },
  mounted(){
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app');
export default vm;
//监听网络断开连接，跳转到404页面
/*window.addEventListener('offline', function() {
    router.replace({name:"error"})
});*/