<style>
  .hyEmpty .van-empty__image {
    width: 16rem;
    height: 16rem;
  }
</style>
<template>
  <van-empty class="hyEmpty" :description="text" :image="imageSrc"></van-empty>
</template>
<script>
export default {
    name: 'hy-empty',
    props:{
      text:{default:"暂无内容"},
      image:{default:""},       //自定义图片地址，如果为空则显示 imageType的默认值
      imageType:{default:""}   //默认的图片地址类别，自带的图片地址
    },
    computed:{
        imageSrc(){
            let thisType = this.imageType;
            let resSrc = null;
            if(isEmpty(this.image)){      //判断是否增加了图片地址，如果有图片地址，则显示图片地址，没有则显示默认地址
                switch(thisType){
                    default :resSrc=require("@/assets/images/isEmpty.png");break;
                }
            }else{
                resSrc = this.image;
            }
            return resSrc;
        }
    }
}
</script>

