export default[
    {
        path: '/organization',
        name: 'organization',
        component: ()=> import('@/views/organization/'),
    },
    {
        path: '/organization/organMain',
        name: 'organMain',
        component: ()=> import('@/views/organization/organMain'),
    },
];
