<!--公共头部，这个就不用多解释了-->
<style>
  .myhead{z-index:150;position: relative;}
  .myhead .van-icon-arrow-left{color:#000;}
</style>
<template>
<van-nav-bar ref="pubHead" :title="title" :fixed="fixed"  left-text="" :right-text="rightText"  left-arrow
             :placeholder="true" @click-left="onClickLeft" @click-right="onClickRight" class="myhead" >
        <template #right>
                <slot></slot>
        </template>
</van-nav-bar>
</template>
<script>
export default {
  name: 'HelloWorld',
  data(){
    return {
        boxHeight:""
    }
  },
  props: {
    title:{default:"标题"},   //头部名称
    rightText:{default:""}, //右侧按钮名称
    fixed:{default:false},    //是否固定在头部
    url:{default:""},          //后退按钮的跳转地址，如果为空，默认是后退，入托填写地址，则跳转路由
    changeIsUpdate:{default:false},  //是否重置 keepAlive 状态 ，只有在页面是keepAlive时候有用，默认是不重新获取数据 false为不重置
    defaultScrollTop:{default:true}    //是否在后退的时候让 ScrollTop 归零 ，true为归零
  },
  mounted(){
      this.boxHeight = this.$refs.pubHead.$el.offsetHeight;
  },
  methods:{
      onClickLeft(val){
        if(isEmpty(this.url)){
            //判断是否需要重新加载 keepAlive 页面
            this.$store.commit('changeIsUpdate',this.changeIsUpdate);
            if(this.defaultScrollTop){
                $("html").scrollTop(0);
                $("body").scrollTop(0);
            }
            this.$router.go(-1);
            this.$emit("onClickLeft",val)
        }else{
            this.$router.replace({path:this.url});
        }
      },
      onClickRight(){
          this.$emit("onClickRight")
      }
  }
}
</script>

